import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="A Quick Guide To Injection Molding & Rotomolding"
            description="When parts need to be produced countless times, you’ll need a reliable method of doing so. That’s where Painted Rhino’s injection urethane and rotocasting services come in. "
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="A Quick Guide To Injection Urethane & Rotocasting"
            date="December 22, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     When you’re looking for a large number of parts to be crafted consistently, you’ll need to use a tried and true manufacturing
                     method. Depending on the type of product you’re looking for, you’ll probably be using injection molding or rotocasting. Both
                     production methods are highly reliable, as well as highly consistent. Learn a little bit about each below and what you can
                     produce using either method.
                  </p>
                  <h2>Our Injection Urethane Process</h2>
                  <p>
                     Injection molding is a manufacturing process that allows parts to be made in large quantities quickly. First, thermoplastic A and
                     B side material is injected into an epoxy or aluminum mold. It’s done at a high speed so it fills the cavity and takes the shape
                     of the mold. Once the material has cooled, the mold is opened and the resulting product is removed. The products can then be
                     painted to a class A finish.
                  </p>
                  <p>
                     The process produces detailed products with industrial-grade durability. One of the big benefits of injection molding is how
                     repeatable the process is. It can be used to create products thousands, even millions of times, in succession and produces
                     consistent results every time. Plus, there’s a minimal amount of waste and scrap.
                  </p>

                  <h3>Examples of Injection Molding</h3>
                  <p>Many industries use injection molding in one way or another to create products such as:</p>
                  <ul>
                     <li>Automotive parts (such as car bumpers)</li>
                     <li>Food and beverage containers</li>
                     <li>Household products like cleaners and storage solutions</li>
                     <li>Electronics housing and cases</li>
                  </ul>

                  <h2>Our Rotocasting Process</h2>
                  <p>
                     Rotocasting is sometimes called rotomolding or centrifugal casting. It’s similar to injection molding since it can produce large
                     amounts of products or parts with the same overall quality. However, the method of creating these products is different from
                     injection molding. Rather than filling cavities through injection, the material is rotated inside a mold, so it forms to the
                     inner walls to create hollow items.
                  </p>
                  <p>
                     A self-curing resin is put into the mold’s cavity while still at a temperature that makes them flexible. Then, the mold is spun
                     while the material is inside it. The centrifugal force makes the material conform to the inner walls of the mold, where it later
                     cools and solidifies. The result is a hollow plastic part.
                  </p>

                  <h3>Examples of Rotocasting</h3>
                  <p>There are a large number of products that can be created using rotocasting. Take a look at some of them below:</p>
                  <ul>
                     <li>Boat pontoons</li>
                     <li>Construction and roadway cones</li>
                     <li>Fuel tanks</li>
                     <li>Recreational coolers</li>
                     <li>Toolboxes</li>
                  </ul>

                  <h2>Looking for an Injection Molding or Rotocasting Manufacturer?</h2>
                  <p>
                     At Painted Rhino, we specialize in bringing your products to life using both injection molding and rotocasting. We’re able to
                     walk you through all of the steps necessary in creating the perfect product, from design to prototyping, all the way to
                     full-scale production. If you need to make a large volume of parts that are all the same,{" "}
                     <a data-modal-open="modal-contact">reach out to us</a>, and we’ll help you get started today!
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/injection-molding-and-rotomolding.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 833) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
